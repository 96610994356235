<template>
    <div>
        <v-container grid-list-xl style="">
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12>
                    <!--<h3>Films</h3>-->
                </v-flex>
            </v-layout>

            <demo-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></demo-data-table>
        </v-container>

        <v-snackbar
            auto-height
            v-model="$store.getters.snackbar.display"
            :bottom="$store.getters.snackbar.y === 'bottom'"
            :left="$store.getters.snackbar.x === 'left'"
            :multi-line="$store.getters.snackbar.mode === 'multi-line'"
            :right="$store.getters.snackbar.x === 'right'"
            :timeout="$store.getters.snackbar.timeout"
            :top="$store.getters.snackbar.y === 'top'"
            :vertical="$store.getters.snackbar.mode === 'vertical'"
            >
            {{ $store.getters.snackbar.text }}
            <v-btn
                color="white"
                text
                @click="snackbar"
            >
                Zapri
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import DemoDataTable from '@/components/DemoDataTable.vue'
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'

export default {
    components: {
        DemoDataTable
    },
    name: 'films',
    data: () => ({
        dataTableSettings: {
            id: 'dtFilms',
            title: 'Films',
            sortColumn: ['film_id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "film_id", align: "left", visibility: true},
                { text: "Title", value: "title", visibility: true},
                { text: "Description", value: "description", visibility: false },
                { text: "Release Year", value: "release_year", visibility: true },
                { text: "Length", value: "length", visibility: true },
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/films',
            editItem: {
                route: "edit_film",
                routeParameterValue: 'film_id'
            },
            deleteItem: {
                vuexAction: 'DELETE_RFID_USER',
                replaceStrWithColumnValue: 'title',
                confirmationText : "Ste prepričani da želite odstraniti film {str_to_replace} ?",
                dialogConfirmSuccessText: 'Filem {str_to_replace} je bil uspešno odstranjen.',
                dialogConfirmFailText: 'Pri odstranjevanju filma {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: true,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'films',
                totalItems : {
                    event: ''
                },
                search: 'film-search',
                toggleColumnsId: 'toggle-film-list-columns'
            }


        },

        startDate: null,
        endDate: null
    }),

    watch: {

    },

    computed: {
        queryParameters() {
            return {
                startDate: this.startDate,
                endDate: this.endDate
            }
        }
    },

    methods: {
        snackbar() {
            this.$store.state.snackbar.display = false
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        //window.console.log(this.$route.name)
    },

    mounted() {
        var vm = this
        setTimeout(function() {
            vm.dataTableSettings.headers[0].visibility = false
        }, 5000)
    },

    beforeDestroy() {

    },

    destroyed() {

    }
}

</script>

<style>

</style>